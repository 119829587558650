import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormAction } from '../../../../contexts/FormActionContext';
import { useFormRendererInfo } from '../../../../contexts/FormRendererContext';
import { CollapsedForm, CollapsedFormType } from '../../../shared/collapsed-form/CollapsedForm';
import ActionBaseProps from '../ActionBaseProps';
import ActionTitleDescription from '../ActionTitleDescription';
import Button, { ButtonType, ButtonSize } from '../../../shared/form-control/Button';
import ReferenceDocumentModal from '../../../documents/reference-document/ReferenceDocumentModal';
import ReferenceIcon from '../../../shared/icon/ReferenceIcon';
import ClientFormService from '../../../../services/ClientFormService';
import { EventSystem } from '../../../../events/EventSystem';

export type DocumentReferenceAnswer = {
  id: string;
};

type DocumentReferenceActionProps = ActionBaseProps<DocumentReferenceAnswer[]>;

const DocumentReferenceAction: FC<DocumentReferenceActionProps> = (props) => {
  const { response, data, required } = props;
  const { clientFormId } = useFormRendererInfo();
  const { onAnswer, readOnly } = useFormAction(props);

  const { t } = useTranslation(['activity-type', 'form-builder']);

  const [referenceModalOpen, setReferenceModalOpen] = useState(false);

  const onReferenceDone = useCallback(
    (documentIds: DocumentReferenceAnswer[]) => {
      if (!clientFormId) {
        return;
      }

      // De-duplicate answer
      const newAnswerSeen: Record<string, boolean> = {};
      const deDuplicatedAnswer = [...(response || []), ...documentIds].filter((x) => (newAnswerSeen[x.id] ? false : (newAnswerSeen[x.id] = true)));
      // Now remove any answers that was de-selected in the pick-list
      const answer = deDuplicatedAnswer.filter((x) => !!documentIds.find((doc) => doc.id === x.id));

      const newItems = answer.filter((x) => !response?.find((response) => x.id === response.id));
      const removedItems = (response || []).filter((id) => !answer.find((response) => id === response));

      Promise.all([
        ClientFormService.createReference(
          clientFormId,
          newItems.map((x) => x.id),
          true,
        ),
        ...removedItems.map((x) => ClientFormService.removeReference(clientFormId, x.id)),
      ]).then(() => {
        EventSystem.fireEvent('associations-updated', null);
        onAnswer(answer);
      });
    },
    [clientFormId, onAnswer, response],
  );

  return (
    <div data-cy="document-reference-action">
      <ActionTitleDescription required={required} {...data} />
      {response?.map((x) => <CollapsedForm formType={CollapsedFormType.AssociatedForm} formId={x.id} key={x.id} data-cy={`doc-ref-${x.id}`} />)}

      <Button type={ButtonType.PRIMARY} onClick={() => setReferenceModalOpen(true)} disabled={readOnly} size={ButtonSize.S} data-cy="add-doc-ref">
        <Button.Slot name="Icon">
          <ReferenceIcon className="h-4 w-4" />
        </Button.Slot>
        {t('document-reference.buttons.add')}
      </Button>
      <ReferenceDocumentModal
        open={referenceModalOpen}
        selectedItems={response || []}
        onClose={() => setReferenceModalOpen(false)}
        onComplete={onReferenceDone}
      />
    </div>
  );
};

export default DocumentReferenceAction;
